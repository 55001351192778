import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { Fragment, ReactElement } from 'react';

const coachesPath = '/teams/u9/coaches';

const U9TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='F-Jugend'>
      <Paragraph>
        Im Bereich der F-Jugend werden keine Meisterschaftsrunden ausgetragen. Stattdessen finden vereinzelt F-Jugend-Spieltage statt. <br />
        Spielberechtigt sind die Jahrgänge 2016 und 2015.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Spieltage' />
      <Paragraph>
        Aktuell sind keine Spielpläne vorhanden.
      </Paragraph>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/u9/Mannschaftsfoto.jpeg' title='F-Jugend 2022/2023' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Jens Luppold'
          position='Trainer'
          phone='-'
          mail='f-jugend@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Jens_Luppold.jpg` }
        />
        <ContactCard
          name='Markus Weinmann'
          position='Trainer'
          phone='-'
          mail='f-jugend@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Markus_Weinmann.jpg` }
        />
        <ContactCard
          name='Kevin Seifert'
          position='Trainer'
          phone='-'
          mail='f-jugend@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Kevin_Seifert.jpg` }
        />
        <ContactCard
          name='Alex Schick'
          position='Trainer'
          phone='-'
          mail='f-jugend@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Alex_Schick.jpg` }
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U9TeamPage };
