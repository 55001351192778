import { TrainingEvent } from '../../domain/TrainingEvent';

const getTrainingEvents = (): TrainingEvent[] => {
  const events: TrainingEvent[] = [
    { title: '1. / 2. Mannschaft', daysOfWeek: [ 2, 4 ], startTime: '19:30', endTime: '21:00' },
    { title: 'B-Jugend', daysOfWeek: [ 2, 4 ], startTime: '17:30', endTime: '19:00' },
    { title: 'C-Jugend (Gymnasium)', daysOfWeek: [ 1 ], startTime: '17:30', endTime: '19:00' },
    { title: 'C-Jugend', daysOfWeek: [ 3 ], startTime: '17:30', endTime: '19:00' },
    { title: 'D-Jugend', daysOfWeek: [ 1, 4 ], startTime: '17:30', endTime: '19:00' },
    { title: 'E-Jugend (Gymnasium)', daysOfWeek: [ 3 ], startTime: '17:30', endTime: '19:00' },
    { title: 'E-Jugend', daysOfWeek: [ 5 ], startTime: '17:30', endTime: '19:00' },
    { title: 'F-Jugend (Gymnasium)', daysOfWeek: [ 3 ], startTime: '17:30', endTime: '19:00' },
    { title: 'F-Jugend', daysOfWeek: [ 6 ], startTime: '09:00', endTime: '11:00' },
    { title: 'Bambini', daysOfWeek: [ 6 ], startTime: '09:30', endTime: '11:00' },
    { title: 'AH-Mannschaft', daysOfWeek: [ 5 ], startTime: '19:00', endTime: '21:00' }
  ];

  return events;
};

export { getTrainingEvents };
