import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { FussballdeWidgetContainer } from '../../../components/FussballdeWidgetContainer';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { SeniorFixtureWidget } from './components/SeniorFixtureWidget';
import { SeniorTableWidget } from './components/SeniorTableWidget';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { Fragment, ReactElement } from 'react';

const coachesPath = '/teams/senior/coaches';

const SeniorTeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='1. Mannschaft'>
      <Paragraph>
        Unsere 1. Mannschaft wurde 2018 Meister der Kreisliga B und ist seither in der Kreisliga A vertreten.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Tabelle und Begegnungen' />
      <FussballdeWidgetContainer>
        <SeniorFixtureWidget />
        <SeniorTableWidget />
      </FussballdeWidgetContainer>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/senior/Mannschaftsfoto.jpg' title='1. Mannschaft 23/24' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Osman Güngör'
          position='Trainer'
          phone='-'
          mail='-'
          thumbnailUrl={ `${coachesPath}/Osman_Guengoer.jpg` }
        />
        <ContactCard
          name='Marco Herter'
          position='Spielertrainer'
          phone='-'
          mail='-'
          thumbnailUrl={ `${coachesPath}/Marco_Herter.jpg` }
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { SeniorTeamPage };
