import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { FussballdeWidgetContainer } from '../../../components/FussballdeWidgetContainer';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { U23FixtureWidget } from './components/U23FixtureWidget';
import { U23TableWidget } from './components/U23TableWidget';
import { Fragment, ReactElement } from 'react';

const coachesPath = '/teams/u23/coaches';

const U23TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='2. Mannschaft'>
      <Paragraph>
        Unsere 2. Mannschaft wurder das erste mal zur Saison 2017/2018 gemeldet und ist seither in der Kreisliga B vertreten.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Tabelle und Begegnungen' />
      <FussballdeWidgetContainer>
        <U23FixtureWidget />
        <U23TableWidget />
      </FussballdeWidgetContainer>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/u23/Mannschaftsfoto.jpg' title='2. Mannschaft 23/24' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='David Gucwa'
          position='Spielertrainer'
          phone='0152 21807697'
          mail='davidgucwa94@web.de'
          thumbnailUrl={ `${coachesPath}/David_Gucwa.jpg` }
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U23TeamPage };
