import { ClubPage } from './components/ClubPage';
import { ContactPage } from './components/ContactPage';
import { Events2017Page } from './app/gallery/components/Events2017Page';
import { Events2018Page } from './app/gallery/components/Events2018Page';
import { Events2019Page } from './app/gallery/components/Events2019Page';
import { Events2022Page } from './app/gallery/components/Events2022Page';
import { Events2023Page } from './app/gallery/components/Events2023Page';
import { Events2024Page } from './app/gallery/components/Events2024Page';
import { FaqPage } from './app/faq/FaqPage';
import { Footer } from './components/Footer';
import { FvSvhPage } from './components/FvSvhPage';
import { GalleriesSectionPage } from './app/gallery/components/GalleriesSectionPage';
import { GymnastikPage } from './app/gymnastik/GymnastikPage';
import { Header } from './components/Header';
import { HobbyTeamPage } from './app/teams/hobby/HobbyTeamPage';
import { ImpressumPage } from './app/impressum/ImpressumPage';
import { LandingPage } from './components/LandingPage';
import { Matches202122Page } from './app/gallery/components/Matches202122Page';
import { Matches202223Page } from './app/gallery/components/Matches202223Page';
import { Matches202324Page } from './app/gallery/components/Matches202324Page';
import { Matches202425Page } from './app/gallery/components/Matches202425Page';
import { NavbarDesktop } from './components/NavbarDesktop';
import { NavbarMobile } from './components/NavbarMobile';
import { NewsPage } from './app/news/NewsPage';
import { PageLayout } from './layout/PageLayout';
import { PrivacyPolicyPage } from './app/privacy/PrivacyPolicyPage';
import { ReactElement } from 'react';
import { ScrollToTop } from './components/ScrollToTop';
import { SeniorTeamPage } from './app/teams/senior/SeniorTeamPage';
import { SeniorTeamsOverview } from './components/SeniorTeamsOverview';
import { Theme } from './layout/Theme';
import { ThemeProvider } from 'styled-components';
import { Trips2021Page } from './app/gallery/components/Trips2021Page';
import { Trips2022Page } from './app/gallery/components/Trips2022Page';
import { Trips2023Page } from './app/gallery/components/Trips2023Page';
import { U11TeamPage } from './app/teams/u11/U11TeamPage';
import { U13TeamPage } from './app/teams/u13/U13TeamPage';
import { U15TeamPage } from './app/teams/u15/U15TeamPage';
import { U17TeamPage } from './app/teams/u17/U17TeamPage';
import { U23TeamPage } from './app/teams/u23/U23TeamPage';
import { U7TeamPage } from './app/teams/u7/U7TeamPage';
import { U9TeamPage } from './app/teams/u9/U9TeamPage';
import { YouthTeamsOverview } from './components/YouthTeamsOverview';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

const App = function (): ReactElement {
  return (
    <ThemeProvider theme={ Theme }>
      <PageLayout>
        <Router>
          <ScrollToTop />
          <NavbarDesktop />
          <NavbarMobile />
          <Header />
          <Switch>
            <Route exact={ true } path='/'>
              <Redirect to='/aktuell' />
            </Route>
            <Route exact={ true } path='/aktuell' component={ LandingPage } />
            <Route path='/news' component={ NewsPage } />

            <Route exact={ true } path='/mannschaften' component={ SeniorTeamsOverview } />
            <Route exact={ true } path='/mannschaften/1_mannschaft' component={ SeniorTeamPage } />
            <Route exact={ true } path='/mannschaften/2_mannschaft' component={ U23TeamPage } />
            <Route exact={ true } path='/mannschaften/ah_mannschaft' component={ HobbyTeamPage } />

            <Route exact={ true } path='/jugend' component={ YouthTeamsOverview } />
            {/* <Route exact={ true } path='/jugend/u19' component={ U19TeamPage } /> */}
            <Route exact={ true } path='/jugend/u17' component={ U17TeamPage } />
            <Route exact={ true } path='/jugend/u15' component={ U15TeamPage } />
            <Route exact={ true } path='/jugend/u13' component={ U13TeamPage } />
            <Route exact={ true } path='/jugend/u11' component={ U11TeamPage } />
            <Route exact={ true } path='/jugend/u9' component={ U9TeamPage } />
            <Route exact={ true } path='/jugend/u7' component={ U7TeamPage } />

            <Route exact={ true } path='/gymnastik' component={ GymnastikPage } />

            <Route exact={ true } path='/galerie' component={ GalleriesSectionPage } />
            <Route path='/galerie/spiele/2021' component={ Matches202122Page } />
            <Route path='/galerie/spiele/2022' component={ Matches202223Page } />
            <Route path='/galerie/spiele/2023' component={ Matches202324Page } />
            <Route path='/galerie/spiele/2024' component={ Matches202425Page } />
            <Route path='/galerie/ausfluege/2021' component={ Trips2021Page } />
            <Route path='/galerie/ausfluege/2022' component={ Trips2022Page } />
            <Route path='/galerie/ausfluege/2023' component={ Trips2023Page } />
            <Route path='/galerie/events/2024' component={ Events2024Page } />
            <Route path='/galerie/events/2023' component={ Events2023Page } />
            <Route path='/galerie/events/2022' component={ Events2022Page } />
            <Route path='/galerie/events/2019' component={ Events2019Page } />
            <Route path='/galerie/events/2018' component={ Events2018Page } />
            <Route path='/galerie/events/2017' component={ Events2017Page } />

            <Route path='/verein'>
              <ClubPage />
            </Route>
            <Route path='/foerderverein'>
              <FvSvhPage />
            </Route>
            <Route path='/kontakte'>
              <ContactPage />
            </Route>

            <Route exact={ true } path='/faq' component={ FaqPage } />
            <Route exact={ true } path='/impressum' component={ ImpressumPage } />
            <Route exact={ true } path='/datenschutz' component={ PrivacyPolicyPage } />

            <Route path='/'>
              <Redirect to='/aktuell' />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </PageLayout>
    </ThemeProvider>
  );
};

export default App;
