import { GalleryPreviewMetadata } from '../../domain/GalleryPreviewMetadata';

const getLatestGalleries = (): GalleryPreviewMetadata[] => [
  {
    title: 'SVH : SGM Ringingen/Killertal (0:3)',
    date: '25.08.2024',
    routePath: 'galerie/spiele/2024/svh-killertal-25-08-24',
    previewPath: `/galleries/matches/2024-2025/svh-killertal-25-08-24/standard/img_0010.jpg`
  },
  {
    title: 'SVH : TSV Boll (1:2)',
    date: '26.05.2024',
    routePath: 'galerie/spiele/2023/svh-boll-26-05-24',
    previewPath: `/galleries/matches/2023-2024/svh-boll-26-05-24/standard/img_0020.jpg`
  },
  {
    title: 'SVH : SGM SV Ringingen/FC Killertal 04 (3:0)',
    date: '07.04.2024',
    routePath: 'galerie/spiele/2023/svh-killertal-07-04-24',
    previewPath: `/galleries/matches/2023-2024/svh-killertal-07-04-24/standard/img_0013.jpg`
  }
];

export { getLatestGalleries };
