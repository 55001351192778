import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { FussballdeWidgetContainer } from '../../../components/FussballdeWidgetContainer';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { U11FixtureWidget } from './components/U11FixtureWidget';
import { U11TableWidget } from './components/U11TableWidget';
import { Fragment, ReactElement } from 'react';

const U11TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='E-Jugend'>
      <Paragraph>
        Unsere E-Jugend spielt in der aktuellen Saison in der Qualigruppe 5. <br />
        Spielberechtigt sind die Jahrgänge 2014 und 2013.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Tabelle und Begegnungen' />
      <FussballdeWidgetContainer>
        <U11FixtureWidget />
        <U11TableWidget />
      </FussballdeWidgetContainer>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/u11/Mannschaftsfoto.jpg' title='E-Jugend 2021/2022' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Semir Zaiane'
          position='Trainer'
          phone='-'
          mail='e-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Jens Noll'
          position='Trainer'
          phone='-'
          mail='e-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U11TeamPage };
